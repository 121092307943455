@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)
// box-shadow: 0px .33rem 1rem -0.33rem rgba(23, 43, 77, 0.2)
.EditorMetricsSidebar
    @include flex(column, flex-start, flex-start)

    background: var(--color-white-faint)
    padding: 0
    gap: 0
    justify-content: space-between
    flex: 1

    // .UseCaseSidebarBlogContentWrapper
    //     @include flex(column, flex-start, flex-start)

    // .UseCaseSidebarBlogHeader
    //     @include flex(row, center, flex-start)

    //     height: 3.67rem
    //     padding: 0 1.67rem
    //     border-bottom: $border-style

    // .UseCaseSidebarBlogBody
    //     @include flex(column, center, flex-start)

    // // .UseCaseSidebarFooter
    // //     @include flex(row, center, flex-start)

    // //     height: 3.67rem
    // //     padding: 0 1.67rem
    // //     border-top: $border-style
