@import "content-studio/src/lib/sass/index.sass"

.BlankHistoryList
    @include flex(column, center, center)

    flex: 1 1

    .BlankHistoryList__content
        @include flex(column, center, center)

        gap: 1.67rem
        color: var(--color-black-light)
        max-width: 75%
        align-self: center
        transform: translateY(50%)

        img
            height: 8rem
            width: 8rem

        h4
            @include font-h4

            font-weight: 700

        .message
            @include flex(column, center, center)

            gap: 0.33rem
            text-align: center
