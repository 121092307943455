@import "./shadow/shadow.sass"
@import "./spacing/spacing.sass"
@import "./typography/typography.sass"
@import "./generic-mixins.sass"

*
    box-sizing: border-box

::-webkit-scrollbar
    width: .33rem

::-webkit-scrollbar-button
    display: none

::-webkit-scrollbar-track-piece
    background: transparent

::-webkit-scrollbar-thumb
    background: rgba(0,0,0,0.2)
