@import "content-studio/src/lib/sass/index.sass"

.HistoryRenderer
    align-self: stretch
    grid-gap: 1rem
    gap: 1rem
    padding: 0
    display: grid
    grid-template-columns: 1fr

.HistoriesList
    display: grid
    grid-template-columns: 1fr

    gap: 0
    margin: 0 0 20rem 0
    padding: 1.33rem 0.67rem 0 0.67rem

    .loading-state
        display: grid
        align-items: center
        justify-content: center
        grid-template-rows: repeat(2, auto)
        grid-gap: 1rem
        margin-top: 5rem

        .message
            color: var(--color-black-light)
