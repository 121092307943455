:root 
    --color-primary-dark: #3107B7
    --color-primary-standard: #6E40FF
    --color-primary-light: #9576FA
    --color-primary-subdued: #DDD4F9
    --color-primary-faint: #F6F4FD

    --color-secondary-dark: #008377
    --color-secondary-standard: #00B782
    --color-secondary-light: #34D396
    --color-secondary-subdued: #94F7BE
    --color-secondary-faint: #C9FBD9

    --color-warning-dark: #B77B23
    --color-warning-standard: #FFC148
    --color-warning-light: #FFD576
    --color-warning-subdued: #FFEDB5
    --color-warning-faint: #FEF7DA

    --color-danger-dark: #B72742
    --color-danger-standard: #FF4F51
    --color-danger-light: #FF857A
    --color-danger-subdued: #FFC9B8
    --color-danger-faint: #FFE7DB

    --color-success-dark: #008377
    --color-success-standard: #00B782
    --color-success-light: #34D396
    --color-success-subdued: #94F7BE
    --color-success-faint: #C9FBD9

    --color-black-dark: #091E42
    --color-black-standard: #172B4D
    --color-black-light: #6B778C
    --color-black-subdued: #C1C7D0
    --color-black-faint: #DFE1E6

    --color-white-dark: #DFE1E6
    --color-white-standard: #EBECF0
    --color-white-light: #F4F5F7
    --color-white-subdued: #FAFBFC
    --color-white-faint: #FFFFFF
    