@import "content-studio/src/lib/sass/index.sass"

.DraftJsEditor
    align-self: stretch
    .wrapper-class
        width: 100%

    .editor-class
        height: calc(100vh - 3.67rem)
        overflow-y: auto !important
        color: var(--color-black-standard)
        padding: 1rem 0
        -ms-overflow-style: none // Internet Explorer 10+
        scrollbar-width: none // Firefox
        position: relative
        margin: 0 auto
        max-width: 61rem
        width: 61rem

        &::-webkit-scrollbar
            display: none // Safari and Chrome

        .DraftEditor-root
            span[data-offset-key]
                display: inline

    .toolbar-class
        justify-content: center
        align-self: stretch
        margin: 0 auto
        min-height: 3.67rem
        border: none // Resets the default border
        border-bottom: 1px solid var(--color-black-faint)
        border-radius: 0
        gap: 0.83rem

    &[data-readonly="true"]
        .toolbar-class
            display: none

    /*****************************
     * Draft editor's css override
     *****************************/
    .public-DraftEditorPlaceholder-root
        position: relative

        &.public-DraftEditorPlaceholder-hasFocus
            .public-DraftEditorPlaceholder-inner
                display: none

        .public-DraftEditorPlaceholder-inner
            color: #aaaaaa
            position: absolute
            pointer-events: none
            font-weight: 400
            font-family: "Inter", sans-serif
            line-height: 1.625
            font-size: 1rem

    .DraftEditor-editorContainer
        a
            color: -webkit-link
            cursor: pointer
            text-decoration: underline
        *
            // margin: 0
            padding: 0

        .public-DraftStyleDefault-block
            span[data-text="true"]
                font-family: "Inter", sans-serif


    .rdw-option-wrapper
        border: none
        height: 2rem
        width: 2rem
        align-items: center
        justify-content: center
        display: flex

        img
            width: 3.33rem
            height: auto

        &:hover
            background: var(--color-white-light)
            box-shadow: none

        &:active,
        &.rdw-option-active
            background: var(--color-white-standard)
            box-shadow: none

            &:hover
                background-color: var(--color-white-dark)
    .rdw-link-modal
        height: auto
        .rdw-link-modal-target-option
            display: flex
            align-items: center
            white-space: nowrap
        #openLinkInNewWindow
            &::after
                display: none
    div[data-contents="true"]
        padding-bottom: 64vh

    .rdw-image-modal
        background: var(--color-white-faint)
        border-radius: 0.67rem
        border: none
        border-top: 1px solid var(--color-white-dark)
        box-shadow: 0 0.67rem 1rem 0 rgb(23 43 77 / 32%)
        padding: 1rem

        .rdw-image-modal-header-option
            font-size: .83rem
            width: auto

    .toolbar-block,
    .toolbar-fontsize
        font-size: 1rem
        border: 1px solid var(--color-white-standard)
        border-radius: 0.33rem

        &:hover
            background: var(--color-white-light)
            box-shadow: none

        .toolbar-block-dropdown,
        .toolbar-fontsize-dropdown
            background: var(--color-white-faint)
            border: none
            border-top: 1px solid var(--color-white-dark)
            box-shadow: 0 0.67rem 1rem 0 rgb(23 43 77 / 32%)
            padding: 0.33rem 0
            position: relative
            width: 100%
            overflow-y: hidden

            .rdw-dropdownoption-default
                padding: 0 0 0 .42rem

            .rdw-dropdownoption-active
                background: var(--color-primary-faint)
                box-shadow: none
                font-weight: 500
                color: var(--color-primary-standard)

                &:hover
                    background-color: var(--color-white-dark)

    .toolbar-fontsize
        width: 4rem

        .toolbar-fontsize-dropdown
            overflow-y: auto

            .rdw-dropdownoption-default
                justify-content: left
