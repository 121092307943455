.primary{
    color: var(--color-primary-normal) ;
}
.primary--bg{
    background-color: var(--color-primary-normal) ;
}
.primary--light{
    color: var(--color-primary-light);
}

.secondary{
    color: var(--color-secondary-normal);
}
.secondary--bg{
    color: var(--color-secondary-normal);
}
.secondary--light{
    color: var(--color-secondary-normal-light);
}

.grayscale{
    color: var(--color-dark-normal);
}
.grayscale--4{
    color: var(--color-grey-4);
}
.grayscale--3{
    color: var(--color-grey-3);
}
.grayscale--2{
    color: var(--color-grey-2);
}
.grayscale--1{
    color: var(--color-grey-1);
}

.black{
    color: var(--color-black-normal);
}
.black--deep{
    color: var(--color-black-deep);
}

.white{
    color: var(--color-white);
}
.white--off{
    color: var(--color-white-off);
}

.alert--success{
    color: var(--color-success-bg);
    background-color: var(--color-success-font);
}
.alert--warning{
    color: var(--color-warning-font);
    background-color: var(--color-warning-font);
}
.alert--danger{
    color: var(--color-danger-bg);
    background-color: var(--alert-danger-bg);
}
.alert--info{
    color: var(---color-warning-font);
    background-color: var(--color-info-font);
}