:root {
    --space-xs: 0.333rem; /* 4px */
    --space-s: 0.667rem; /* 8px */
    --space-base: 1rem; /* 12px */
    --space-l: 1.333rem; /* 16px */
    --space-xl: 1.667rem; /* 20px */
    --space-xxl: 2rem; /* 24px */

    /*  
        28px = xxl + xs
        32px = xxl + s
        36px = xxl + base
        40px = xxl + l
        44px = xxl + xl
        48px = xxl * 2;
        ...
        52px = 2*xxl + xs
        64px = 2*xxl + s
    */
}