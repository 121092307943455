@import "content-studio/src/lib/sass/index.sass"

.Menu
    @include font-body
    @include flex(column, flex-start, center)

    position: relative

    &[data-align="right"]
        .menu-container
            left: unset
            right: 0

    .menu-select
        @include flex(row, flex-start, flex-start)

    .menu-container
        @include flex(column, flex-start, flex-start)
        @include shadow-dropdown

        border-top: 1px solid var(--color-white-dark)
        position: relative
        padding: 0
        border-radius: .67rem
        overflow: hidden
        position: absolute
        top: 3.33rem
        left: 0
        width: 10rem

    .menu-header
        @include flex(row, flex-start, flex-start)

        padding: 0.67rem

        .TextField
            @include flex(column, flex-start, flex-start)

            width: 100%

            .TextFieldInput
                width: 100%

    .menu-body
        @include flex(column, flex-start, flex-start)

        gap: 0
        max-height: 20rem
        padding: 0.33rem 0
        overflow: auto
        background: var(--color-white-faint)

        .MenuItem
            @include flex(row, flex-start, flex-start)
