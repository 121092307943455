/*! Here defined the typograhy classes. Already present in /stylesHomepage */


:root {
	--font-weight-bold: 700;
	--font-weight-medium: 500;
	--font-weight-regular: 400;

	--font-size-h1: 1.75rem;
	--font-size-h2: 1.5rem;
	--font-size-h3: 1.25rem;
	--font-size-body: 1rem;
	--font-size-small: .75rem;
	--font-size-button: .875rem;

	--font-line-height-h1: 2rem;
	--font-line-height-h2: 1.75rem;
	--font-line-height-h3: 1.5rem;
	--font-line-height-body: 1.375rem;
	--font-line-height-small: 1rem;
	--font-line-height-button: .875rem;
}

/* Everything above is osolete */
.weight-bold {
    font-weight: var(--font-weight-bold) !important;
}
.weight-medium {
    font-weight: var(--font-weight-medium) !important;
}
.weight-regular {
    font-weight: var(--font-weight-regular) !important;
}

/* In our typography definition, there's no H4, H5 and H6 */
h1,
.h1 {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-h1); /* 28px */
    line-height: var(--font-line-height-h1); /* 32px */
}

h2,
.h2 {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-h2); /* 24px */
    line-height: var(--font-line-height-h2); /* 28px */
}

h3,
.h3 {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-h3); /* 20px */
    line-height: var(--font-line-height-h3); /* 24px */
}

.font-body {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-body); /* 16px */
    line-height: var(--font-line-height-body); /* 22px */
}

.font-small {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-small); /* 12px */
    line-height: var(--font-line-height-small); /* 16px */
}

.font-button {
    font-family: "Inter";
    font-style: normal;
    font-size: var(--font-size-button); /* 14px */
    line-height: var(--font-line-height-button); /* 14px */
    font-weight: 700;
}

/* Adjust the max-width accordingly. I am assuming its 767px */
@media only screen and (max-width: 767px) {
	:root {
		--font-size-h1: 1.375rem;
		--font-size-h2: 1.25rem;
		--font-size-h3: 1rem;
		--font-size-body: .875rem;
		--font-size-small: .625rem;
		--font-size-button: .813rem;
	
		--font-line-height-h1: 1.625rem;
		--font-line-height-h2: 1.5rem;
		--font-line-height-h3: 1.25rem;
		--font-line-height-body: 1.25rem;
		--font-line-height-small: 0.75rem;
		--font-line-height-button: .815rem;
	}
}
