@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)

.Project
    display: grid
    grid-template-rows: 3.33rem 1fr

    background-color: $brandColor

    .motion-div
        width: 100%

.Page
    background-color: var(--color-white-faint)
    border-top-left-radius: 1.33rem
    border-top-right-radius: 1.33rem

.ButtonRound
    @include flex(row, center, center)
    @include font-h4
    padding: .67rem
    width: 2.67rem
    height: 2.67rem
    background: var(--color-white-faint)
    box-shadow: 0px .33rem 0.67rem -0.33rem rgba(23, 43, 77, 0.3)
    border-radius: 83.25rem
    align-self: center
    transition: all 200ms ease-in-out
    cursor: pointer

    &[data-pressed="true"]
        background: var(--color-white-standard)
        box-shadow: none

        &:hover
            box-shadow: none
            background-color: var(--color-white-dark)

    &.ButtonRound--plain,
    &.ButtonRound--outline
        box-shadow: none

        &:hover
            box-shadow: none

        &:active
            background-color: var(--color-white-standard)

    &.ButtonRound--outline
        height: 1.67rem !important
        width: 1.67rem !important
        padding: 0.27rem !important
        border: 1px solid var(--color-primary-standard)

        &:hover
            color: var(--color-white-standard)
            background: var(--color-primary-standard)

        &:active
            color: var(--color-white-standard)
            background-color: var(--color-primary-dark)

    &:hover
        background: var(--color-white-light)
        box-shadow: 0px .33rem 0.67rem -0.33rem rgba(23, 43, 77, 0.6)

.ContainerFixed
    @include flex(row, center, space-between)
    align-self: auto
    width: $maxContainerWidth
    box-shadow: none
    margin: 0 auto

.PageBody
    @include flex(row, flex-start, flex-start)
    gap: 3.33rem
    padding: 1.67rem
    align-self: auto

.TabsContainer
    @include flex(column, flex-start, flex-start)

    gap: 0

    .TabsGroup
        @include flex(row, flex-start, flex-start)

        padding: 0 1.67rem
        gap: 2rem
        border-bottom: $border-style
        // box-shadow: 0 0.33rem 0.33rem -0.17rem rgb(23 43 77 / 8%)
        z-index: 5

        position: relative

        .Tab
            @include flex(row, center, flex-start)
            @include font-body
            gap: .33rem
            height: calc(3.67rem - 1px)
            color: #6B788D
            border-bottom: 2px solid transparent
            cursor: pointer
            transition: all 200ms ease-in-out

            &:hover
                color: #172B4D

            &[data-active="true"]
                color: #172B4D
                border-bottom: 2px solid #172B4D

            &:first-child
                padding-left: 0

            .TabName
                text-transform: capitalize

    .TabBody
        @include flex(column, flex-start, flex-start)
        overflow: auto
        flex: 1
    // Hide scrollbar for Chrome, Safari and Opera
        -ms-overflow-style: none
        scrollbar-width: none
    .TabBody::-webkit-scrollbar
        display: none




// Layout
.Page
    display: grid
    grid-gap: 0
    grid-template-areas: "page-header" "page-banner" "page-body"
    grid-template-columns: 100vw

    .ProjectHeader
        grid-area: page-header

    .UseCaseBlog
        grid-area: page-body

.banner-alert
    @include flex(row, center, center)

    height: 2.33rem
    width: 100vw
    grid-area: page-banner
    font-weight: 500

    &[data-status="archive"]
        color: var(--color-black-standard)
        background-color: var(--color-warning-standard)

    &[data-status="trash"]
        color: var(--color-white-standard)
        background-color: var(--color-danger-standard)

.MoveToTop
    @include default-transition

    position: fixed
    bottom: 1rem
    right: 1rem
    background: var(--color-white-faint)
    height: 3rem
    width: 3rem
    display: flex
    align-items: center
    justify-content: center
    border-radius: 999px
    box-shadow: 0 0.17rem 0.67rem 0 rgba(23, 43, 77, 0.4)
    border: 1px solid transparent
    cursor: pointer
    font-size: 1.67rem

    &:hover
        color: var(--color-primary-standard)
        background: var(--color-primary-faint)
