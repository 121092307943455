@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)
$sidebar-width: 27rem

.UseCaseBlog
    display: grid
    grid-template-columns: 4rem minmax(auto, 1fr) 4rem
    grid-template-areas: "UseCaseBlog-left UseCaseBlog-middle UseCaseBlog-right"
    grid-gap: 0
    padding: 0

    &[data-withsidebars="left"]
        grid-template-columns: 4rem $sidebar-width minmax(auto, 1fr) 4rem
        grid-template-areas: "UseCaseBlog-left UseCaseSidebar UseCaseBlog-middle UseCaseBlog-right"

    &[data-withsidebars="right"]
        grid-template-columns: 4rem minmax(auto, 1fr) $sidebar-width 4rem
        grid-template-areas: "UseCaseBlog-left UseCaseBlog-middle MetricsSidebar UseCaseBlog-right"

    &[data-withsidebars="both"]
        grid-template-columns: 4rem $sidebar-width minmax(auto, 1fr) $sidebar-width 4rem
        grid-template-areas: "UseCaseBlog-left UseCaseSidebar UseCaseBlog-middle MetricsSidebar UseCaseBlog-right"

    .UseCaseBlog-left-col,
    .UseCaseBlog-right-col
        display: grid
        align-items: center
        justify-content: center

    .UseCaseBlog-left-col
        grid-area: UseCaseBlog-left
        grid-auto-rows: 3.67rem
        gap: 1.33rem
        padding: 0.33rem
        border-right: $border-style

    .UseCaseBlog-right-col
        grid-area: UseCaseBlog-right
        border-left: $border-style
        grid-auto-rows: 3.67rem
        gap: 1.33rem
        padding: 0.33rem

    .UseCaseBlog-middle-col
        display: grid
        grid-area: UseCaseBlog-middle
        overflow: hidden

    .UseCaseSidebarBlog,
    .HistorySidebar
        grid-area: UseCaseSidebar
        border-right: $border-style

    .EditorMetricsSidebar
        grid-area: MetricsSidebar
        border-left: $border-style

    .ButtonRound[data-pressed="true"]
        color: var(--color-primary-standard)
        background: var(--color-primary-faint)
