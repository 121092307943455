
*
    box-sizing: border-box

body
    overflow: hidden

::-webkit-scrollbar
    width: .33rem

::-webkit-scrollbar-button
    display: none

::-webkit-scrollbar-track-piece
    background: transparent

::-webkit-scrollbar-thumb
    background: rgba(0,0,0,0.1)
