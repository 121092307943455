@import "content-studio/src/lib/sass/index.sass"
@import "content-studio/src/lib/sass/styles/typography/typography.sass"

.HistoryCard
    display: grid
    grid-template-columns: 1fr
    grid-template-rows: repeat(3, max-content)

    .HistoryCardHeader
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-rows: max-content
        align-items: center

    .HistoryCardBody
        display: grid
        grid-template-columns: 1fr
        grid-template-rows: max-content

    .HistoryCardFooter
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-rows: max-content
        align-items: center

        .HistoryCardActions
            display: flex
            flex-direction: row
            justify-content: flex-end
            gap: 1rem

.HistoryCard
    background: var(--color-white-faint)
    border-radius: 0
    border: 1px solid var(--color-white-faint)
    outline: 1px solid var(--color-white-faint)
    grid-gap: 0.67rem
    z-index: 0
    position: relative
    padding: 1rem

    transition: all 200ms ease-in-out
    cursor: pointer

    &::after
        content: ""
        height: 1px
        background: var(--color-black-faint)
        position: absolute
        bottom: 0
        left: 1rem
        right: 1rem

    &:hover,
    &:active,
    &:focus,
    &:focus-visible
        outline: 1px solid rgba(255,255,255, 0)

    &:hover
        border-radius: .67rem
        border: 1px solid var(--color-black-subdued)
        box-shadow: 0px 0.17rem 1.33rem -0.17rem rgb(23 43 77 / 40%)
        z-index: 10
        transform: none //translateY(-2%)

        &::after
            background: var(--color-white-faint)

    .Badge
        width: max-content
        align-content: center
        height: fit-content
        padding: 0.33rem

    .CopyMessage
        position: absolute
        bottom: 2rem
        z-index: 10
        width: 11rem
        background: var(--color-black-standard)
        color: var(--color-white-faint)
        padding: .33rem
        border-radius: .33rem
        text-align: center

    .Icon-IsUsed
        color: var(--color-success-standard)
        justify-self: flex-end
        font-size: 1.33rem
        line-height: 1
        align-items: center
        grid-gap: 0.17rem

        small
            font-weight: 500
            color: var(--color-success-standard)

    .HistoryCardBody
        @include font-body

        word-break: break-word
        line-height: 1.25
        color: var(--color-black-standard)
        position: relative

        &[data-expandable="true"][data-expanded="false"]
            max-height: 5rem
            overflow: hidden

            &::after
                // content: ""
                position: absolute
                bottom: 0
                left: 0
                right: 0
                height: 1.67rem
                z-index: 1
                background: linear-gradient(0deg, rgba(255,255,255,1) 15%, rgba(0,212,255,0) 100%)

        &[data-expandable="true"][data-expanded="true"]
            height: auto
            padding-bottom: 1.67rem

        .HistoryCardExpander
            display: flex
            align-items: baseline
            gap: .33rem
            color: $brandColor
            font-size: 0.83rem
            position: absolute
            bottom: 0
            right: 0
            padding: 0
            box-shadow: 0 0 1.33rem 0 rgba(255,255,255,0.9)
            z-index: 2
            justify-content: flex-end
            width: 5rem
            background: var(--color-white-faint)
            background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%)

            &[data-expandable="false"]
                visibility: hidden

            &[data-expandable="true"]
                visibility: visible

    .HistoryCardAuthor
        align-items: center
        color: var(--color-primary-standard)
        font-size: 0.83rem
        .author-image
            height: 2rem
            width: 2rem
            border-radius: 100%
            overflow: hidden
            display: flex
            align-items: center

            img
                width: 100%
                height: auto

    .HistoryCardActions
        font-size: 1rem
        color: $brandColor
        grid-template-columns: repeat(2, 2rem) 1fr
        justify-items: center
        align-items: center

        .OutlineButton
            box-shadow: none
            padding: 0.33rem 0.67rem
            &:hover
                color: var(--color-white-standard)
                background-color: var(--color-primary-standard)
                border: 1px solid var(--color-primary-standard)

        .ButtonRound
            position: relative
            padding: 0.5rem
            height: 2rem
            width: 2rem

            &[data-pressed="true"]
                background: var(--color-white-standard)

            &[data-fav="true"]
                background: var(--color-danger-faint)
                svg
                    fill: var(--color-danger-light)
                    stroke: var(--color-danger-standard)

    .Menu
        .menu-container
            top: 3.17rem
            right: -1rem

        .menu-body
            padding: 0.33rem 0

        .MenuItem
            padding: 0.33rem 0.67rem
            color: var(--color-black-standard)

            &:last-child
                color: var(--color-danger-standard)

/// Content formatting 
.HistoryCard
    .su-listBullet
        @include flex(column, flex-start, flex-start)

        list-style-type: disc
        padding-left: 2rem
        gap: .67rem

    .su-listNumber
        @include flex(column, flex-start, flex-start)

        padding-left: 2rem
        gap: .67rem
