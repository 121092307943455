:root{
    /* primary  */
    --color-primary-normal:#6E40FF ;
    --color-primary-light:#E8E0FF;
    /*  */
    --color-primary-normal-alpha: rgba(110, 64, 255, 0.16);
    --color-primary-light-alpha: rgba(232, 224, 255, 0.24);
    /* secondary */
    --color-secondary-normal:#109C72;
    --color-secondary-light:#D9EFE8;

    /* grayscale */
    --color-dark-normal:#172B4D;
    --color-grey-4:#6B788D;
    --color-grey-3:#A2AAB8;
    --color-grey-2:#DADDE2;
    --color-grey-1:#EDEEF1;

    /* blacks */
    --color-black-normal:#201F20;
    --color-black-deep:#0E0D0E;

    /* whites */
    --color-white:#FFFFFF;
    --color-white-alpha-80: rgba(255, 255, 255, 0.8);
    --color-white-alpha-60: rgba(255, 255, 255, 0.6);
    --color-white-alpha-40: rgba(255, 255, 255, 0.4);

    --color-white-off:#F8F9FA;
    
    /* alerts */
    --color-success-font:#006100;
    --color-success-bg:#C6EFCE;
    --color-danger-font: #9c0006;
    --color-danger-bg: #ffc7ce;
    --color-warning-bg:#9c5700;
    --color-warning-font: #ffeb9c;
    --color-info-font: #001e62;
    --color-info-bg:#C6CAF0;


    /* common styles */
    --light-neutral:#F4F5F7;
    --color-grey-5:#6B778C;
    --target-ads-primary-color: #0D3CFE;
}