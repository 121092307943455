@import "../../../../lib/sass/styles/generic-mixins.sass"

.ConfigurationForm
    @include flex(column, flex-start, flex-start)

    align-self: stretch
    gap: 1.33rem

    .input-group
        @include flex(column, flex-start, flex-start)

        padding: 0
        border: none
        gap: .33rem
