@import "content-studio/src/lib/sass/index.sass"

.Badge
    @include flex("row", center, center)
    @include font-body

    line-height: 1
    padding: 0.33rem 0.67rem
    background: var(--color-white-standard)
    color: var(--color-black-standard)
    align-self: initial
    border-radius: .33rem

    &[data-size="sm"]
        @include font-small

        line-height: 1

    &[data-type="danger"]
        background: var(--color-danger-faint)
        color: var(--color-danger-dark)

    &[data-type="warning"]
        background: var(--color-warning-faint)
        color: var(--color-warning-dark)

    &[data-type="success"]
        background: var(--color-success-faint)
        color: var(--color-success-dark)
