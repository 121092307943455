@mixin font-h4
    font-family: "Inter", sans-serif
    font-style: normal
    font-weight: 500
    font-size: 1.42rem
    line-height: 1.67rem

@mixin font-small
    font-family: "Inter", sans-serif
    font-style: normal
    font-weight: 400
    font-size: .83rem
    line-height: 1.67rem

@mixin font-body
    font-family: "Inter", sans-serif
    font-style: normal
    font-weight: 400
    font-size: 1rem
    line-height: 1.67rem

@mixin flex($direction, $align, $justify)
    display: flex
    flex-direction: $direction
    align-items: $align
    justify-content: $justify
    align-self: stretch

@mixin default-transition
    transition: all 200ms ease-in-out

@mixin shadow-dropdown
    box-shadow: 0 0.67rem 1rem 0 rgba(23, 43, 77, 0.32)
