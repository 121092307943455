.intermediaryPage-container{
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 0px;
    margin: 0px 0px;
    flex:1;
    background: #F8F9FA;
    width: 100%;
}

.flow__third__section{
    flex:1;
    /* background: #F8F9FA; */
    /* max-width: ; */
    overflow-y: auto;
}

.intermediaryPage-container__card-section{
    width: 90%;
}

.main-display {
    max-width: 1008px;
    margin: auto;
    padding: 24px;

}
.main-display .endSuggestions {
    margin-top: var(--space-xxl);
}
.main-display__scroll {

    overflow-y: scroll;
    max-height: calc(100vh - 65px);
    margin-top: 4rem;
    height: calc(100vh - 8rem);
    padding: 1.5rem;
}

  
  /* Hide scrollbar for IE, Edge and Firefox */
.main-display {
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */
  }

@media screen and (max-width:1024px) {
    .main-display{
        width: 100%;
    }
}


/* @media screen and (max-width:1824px) {
    .main-display{
        width: 100%;
    }
} */

.question__header-toolbar--span{
    margin-left: 6px;
    font-size: 0.75rem;
    color: rgba(23, 43, 77, 0.64);
}

@media screen and (max-width:1024px) {
    .question__header-toolbar--span{
       display: none;
    }
}



.outline-card-footer{
    padding: 0.875rem;
}

.outline-card-footer .endSuggestions{
 margin-top: var(--space-base);
}
.container-nav > .left{
    display: flex;
    align-items: baseline;
    font-size: 1.5rem;
}

.main__width--fix{
    width: 0% !important;
}
.breadcrumb-icon{
    cursor: pointer;
}



@media screen and (max-width:1280px){
    .steps .nav-item > p {
        margin-top: 8px;
        margin-left: 10px;
    }
    .steps .nav-item{
        align-items: center;
    }

    .display-title-card img{
        height: 1.25rem;
        width: 1.25rem;
    }
    .card-actions img{
        height: 1.25rem;
        width: 1.25rem;
    }
    .form--collapse-icon::after{
        bottom: -1.05rem;
    }
}

.filename-input
        {
        text-align: left;
        resize: none;
        overflow: hidden;
        /* Product/H3/Web/Bold */
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding: 0;
        width: 100%;
        border-radius: 0.25rem;
        margin: 0;
        /* height: 1.5rem; */
        height:3.875rem;
        border: none;
        background: var(--color-black-normal);
        color: var(--color-white);
}


.filename-input:focus{
    outline: 1px solid #6E40FF
}

#nav-Dashboard-textspaNone{
    display: none;
}


.card-expanded-area  p  .inline__quill-editor .quill .ql-toolbar{
    padding: 0 !important;
}


.card-expanded-area  p  .inline__quill-editor .quill .ql-container{
    padding: 0.5rem !important;
}
.card-expanded-area  p  .inline__quill-editor .quill .ql-toolbar.ql-snow:before{
    content: unset;
}

.card-expanded-area  p  .inline__quill-editor .quill .ql-toolbar.ql-snow{
    border: 1px solid #ccc;
}

.card-expanded-area .outline-expanded p .inline__quill-editor{
    background: #FFFFFF;
/* Primary/Normal */

border: 1px solid #6E40FF;
box-sizing: border-box;
border-radius: 4px;
}


.radio{
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.radio__input{
    display: none;
}

.radio__radio{
    min-width: 1.25rem;
    min-height: 1.25rem;
    border: 2px solid #6B788D;
    border-radius: 50%;
    box-sizing: border-box;
    /* padding: 2px; */
    /* margin-right: 1rem; */
    cursor: pointer;
    max-height: 1.25rem;
    align-self: flex-start;
}

.display-title-card > .title-label{
    margin: 0;
    margin-bottom:1rem; ;
}

.radio__radio::after{
    content: "";
    min-width: 60%;
    min-height: 0.6rem;
    display: block;
    /* background: var(--color-secondary-normal); */
    border: 5px solid var(--color-secondary-normal);
    border-radius: 50%;

    transition: transform 0.15s;
    transform: scale(0);

    margin: -2px !important;
}

.radio__input:checked + .radio__radio::after{
    transform: scale(1);
}

.radio__text{
    margin-left: 1rem;
}

.side-project-limit--exists{
    position: absolute;
    width: 4.5rem;
    height: 2.25rem;
    top: 1.25rem;
    right: 1.5rem;

    margin-bottom: 0.5rem;
}

.side-project-limit-text--heading{
    font-style: normal;
font-weight: normal;
font-size: 0.75rem;
line-height: 1rem;
color: #6B788D;

}


.side-project-limit-text--nums{
    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 1rem;
    
    color: #172B4D;
    }


.side-project-limit-text-div{
    text-align: right;
}