@import "../../../../lib/sass/styles/common"
@import "../../../../lib/sass/styles/colors/content-studio.sass"

.UsecaseList
    @include font-body
    @include flex(column, flex-start, flex-start)

    gap: .67rem
    position: relative
    padding-bottom: 0.67rem
    width: 100%

    .TextFieldLabel
        @include font-button
        color: var(--color-black-light)

    .list-select
        @include flex(column, flex-start, flex-start)

        gap: 0.33rem

    .list-container
        @include flex(column, flex-start, flex-start)
        @include shadow-dropdown

        border-top: 1px solid var(--color-white-dark)
        background: var(--color-white-faint)
        position: relative
        padding: 0
        border-radius: .67rem
        overflow: hidden
        position: absolute
        top: 3.33rem
        width: 100%

        &[data-haslabel="true"]
            top: 5.2rem

    .list-header
        @include flex(row, flex-start, flex-start)

        padding: 0.67rem

        .TextField
            @include flex(column, flex-start, flex-start)

            width: 100%

            .TextFieldInput,
            .TextFieldInputComp
                width: 100%

    .list-body
        @include flex(column, flex-start, flex-start)

        gap: 0
        max-height: 30rem !important
        padding: 0.67rem 0
        overflow: auto

        .ListItem
            @include flex(row, flex-start, flex-start)

    .SecondaryButton,
    .PrimaryButton,
    .OutlineButton
        @include flex(row, center, flex-start)
        @include subheading

        position: relative
        width: 100%
        color: var(--color-black-standard) !important

        .usecase-icon
            height: 1.67rem
            width: 1.67rem

        .icon-left
            display: none

        .icon-right
            position: absolute
            right: 1rem
