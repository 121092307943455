@import "content-studio/src/lib/sass/index.sass"

.SelectInput
    @include flex(column, flex-start, flex-start)

    border: none
    padding: 0
    gap: 0.33rem

    .FieldGroup
        align-self: stretch

    .ListItem,
    .Button
        text-transform: capitalize

    .ListItem
        text-transform: capitalize
        font-weight: 400 !important
        font-size: 1rem !important

    .FieldLabel .label-text
        text-transform: capitalize !important

    .Button:focus,
    .Button:focus-visible
        outline: 1px solid var(--color-primary-standard)
