@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)

.UseCaseSidebarBlog
    @include flex(column, flex-start, flex-start)

    padding: 0
    gap: 0
    justify-content: space-between
    flex: 1

    .alertDot
        font-size: 1.67rem
        color: var(--color-danger-standard)
        position: absolute
        top: 0.33rem
