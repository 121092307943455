:root
    --spacing-none: 0
    --spacing-xs: .33rem
    --spacing-s: .67rem
    --spacing-df: 1rem // df = default
    --spacing-m: 1.33rem
    --spacing-l: 1.67rem
    --spacing-xl: 2rem
    --spacing-xxl: 2.67rem
    --spacing-max: 3.33rem

// None spacing utility classes
.g-0
    gap: var(--spacing-none)
.m-0
    margin: var(--spacing-none)
.p-0
    padding: var(--spacing-none)

.mt-0
    margin-top: var(--spacing-none)
.pt-0
    padding-top: var(--spacing-none)

.mr-0
    margin-right: var(--spacing-none)
.pr-0
    padding-right: var(--spacing-none)

.mb-0
    margin-bottom: var(--spacing-none)
.pb-0
    padding-bottom: var(--spacing-none)

.ml-0
    margin-left: var(--spacing-none)
.pl-0
    padding-left: var(--spacing-none)

// Extra small spacing utility classes
.g-xs
    gap: var(--spacing-xs)
.m-xs
    margin: var(--spacing-xs)
.p-xs
    padding: var(--spacing-xs)

.mt-xs
    margin-top: var(--spacing-xs)
.pt-xs
    padding-top: var(--spacing-xs)

.mr-xs
    margin-right: var(--spacing-xs)
.pr-xs
    padding-right: var(--spacing-xs)

.mb-xs
    margin-bottom: var(--spacing-xs)
.pb-xs
    padding-bottom: var(--spacing-xs)

.ml-xs
    margin-left: var(--spacing-xs)
.pl-xs
    padding-left: var(--spacing-xs)

// Small spacing utility classes
.g-s
    gap: var(--spacing-s)
.m-s
    margin: var(--spacing-s)
.p-s
    padding: var(--spacing-s)

.mt-s
    margin-top: var(--spacing-s)
.pt-s
    padding-top: var(--spacing-s)

.mr-s
    margin-right: var(--spacing-s)
.pr-s
    padding-right: var(--spacing-s)

.mb-s
    margin-bottom: var(--spacing-s)
.pb-s
    padding-bottom: var(--spacing-s)

.ml-s
    margin-left: var(--spacing-s)
.pl-s
    padding-left: var(--spacing-s)

// Default spacing utility classes
.g-df
    gap: var(--spacing-df)
.m-df
    margin: var(--spacing-df)
.p-df
    padding: var(--spacing-df)

.mt-df
    margin-top: var(--spacing-df)
.pt-df
    padding-top: var(--spacing-df)

.mr-df
    margin-right: var(--spacing-df)
.pr-df
    padding-right: var(--spacing-df)

.mb-df
    margin-bottom: var(--spacing-df)
.pb-df
    padding-bottom: var(--spacing-df)

.ml-df
    margin-left: var(--spacing-df)
.pl-df
    padding-left: var(--spacing-df)

// Medium spacing utility classes
.g-m
    gap: var(--spacing-m)
.m-m
    margin: var(--spacing-m)
.p-m
    padding: var(--spacing-m)

.mt-m
    margin-top: var(--spacing-m)
.pt-m
    padding-top: var(--spacing-m)

.mr-m
    margin-right: var(--spacing-m)
.pr-m
    padding-right: var(--spacing-m)

.mb-m
    margin-bottom: var(--spacing-m)
.pb-m
    padding-bottom: var(--spacing-m)

.ml-m
    margin-left: var(--spacing-m)
.pl-m
    padding-left: var(--spacing-m)

// Large spacing utility classes
.g-l
    gap: var(--spacing-l)
.m-l
    margin: var(--spacing-l)
.p-l
    padding: var(--spacing-l)

.mt-l
    margin-top: var(--spacing-l)
.pt-l
    padding-top: var(--spacing-l)

.mr-l
    margin-right: var(--spacing-l)
.pr-l
    padding-right: var(--spacing-l)

.mb-l
    margin-bottom: var(--spacing-l)
.pb-l
    padding-bottom: var(--spacing-l)

.ml-l
    margin-left: var(--spacing-l)
.pl-l
    padding-left: var(--spacing-l)

// Extra Large spacing utility classes
.g-xl
    gap: var(--spacing-xl)
.m-xl
    margin: var(--spacing-xl)
.p-xl
    padding: var(--spacing-xl)

.mt-xl
    margin-top: var(--spacing-xl)
.pt-xl
    padding-top: var(--spacing-xl)

.mr-xl
    margin-right: var(--spacing-xl)
.pr-xl
    padding-right: var(--spacing-xl)

.mb-xl
    margin-bottom: var(--spacing-xl)
.pb-xl
    padding-bottom: var(--spacing-xl)

.ml-xl
    margin-left: var(--spacing-xl)
.pl-xl
    padding-left: var(--spacing-xl)

// Extra Extra Large spacing utility classes
.g-xxl
    gap: var(--spacing-xxl)
.m-xxl
    margin: var(--spacing-xxl)
.p-xxl
    padding: var(--spacing-xxl)

.mt-xxl
    margin-top: var(--spacing-xxl)
.pt-xxl
    padding-top: var(--spacing-xxl)

.mr-xxl
    margin-right: var(--spacing-xxl)
.pr-xxl
    padding-right: var(--spacing-xxl)

.mb-xxl
    margin-bottom: var(--spacing-xxl)
.pb-xxl
    padding-bottom: var(--spacing-xxl)

.ml-xxl
    margin-left: var(--spacing-xxl)
.pl-xxl
    padding-left: var(--spacing-xxl)

// Max Large spacing utility classes
.g-max
    gap: var(--spacing-max)
.m-max
    margin: var(--spacing-max)
.p-max
    padding: var(--spacing-max)

.mt-max
    margin-top: var(--spacing-max)
.pt-max
    padding-top: var(--spacing-max)

.mr-max
    margin-right: var(--spacing-max)
.pr-max
    padding-right: var(--spacing-max)

.mb-max
    margin-bottom: var(--spacing-max)
.pb-max
    padding-bottom: var(--spacing-max)

.ml-max
    margin-left: var(--spacing-max)
.pl-max
    padding-left: var(--spacing-max)
