@import "content-studio/src/lib/sass/index.sass"

.ResultRendererHeader
    @include flex(row, center, flex-start)
    gap: 1rem
    padding: 0

    .wrapper
        @include flex(column, flex-start, flex-start)

        gap: .33rem
        align-self: center

    .description
        @include font-small

.ResultRenderer
    align-self: stretch
    grid-gap: 1rem
    gap: 1rem
    padding: 0
    display: grid
    grid-template-columns: 1fr

.ResultsList
    display: grid
    grid-template-columns: 1fr

    gap: 0
    margin: 0 0 6rem 0
    padding: 1.33rem 0.67rem 0 0.67rem

    .Button
        margin: 2rem auto
        width: 8rem
        height: 2rem

    .loading-state
        display: grid
        align-items: center
        justify-content: center
        grid-template-rows: repeat(2, auto)
        grid-gap: 1rem
        margin-top: 5rem

        .message
            color: var(--color-black-light)
