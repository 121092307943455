@import "../../../../lib/sass/styles/generic-mixins.sass"

.RadioInput
    border: none
    padding: 0

    .RadioFieldSet
        @include flex(column, flex-start, flex-start)

        gap: 0.33rem
        border: none

        .InputRadio
            @include flex(row, center, flex-start)

            gap: 0.67rem
