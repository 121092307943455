@import "content-studio/src/lib/sass/index.sass"

.MenuItem
    @include font-body
    @include default-transition
    @include flex(row, center, flex-start)

    padding: 0.33rem 0.67rem
    cursor: pointer
    outline: none
    border-left: .17rem solid transparent
    border-right: .17rem solid transparent
    gap: 0.67rem

    &:hover
        background-color: var(--color-white-light)

    &[data-active="true"]
        color: var(--color-primary-standard)
        font-weight: 700
        border-left-color: var(--color-primary-standard)

    &[data-disable="true"]
        color: var(--color-black-subdued)
        cursor: not-allowed

    &[data-focus="true"],
    &:focus,
    &:focus-visible
        background-color: var(--color-primary-faint)

.MenuItem.Disabled
    background-color: var(--color-white-light)
    opacity: 0.4
    cursor: not-allowed
