@import "content-studio/src/lib/sass/index.sass"

.UserFiles
    background-color: $brandColor
    width: 100vw

    .Page
        @include flex(column, flex-start, flex-start)

        gap: 0
        background-color: #FFF
        border-top-left-radius: 1.33rem
        border-top-right-radius: 1.33rem
        box-shadow: 0 -1rem 0.67rem -0.33rem rgb(23 43 77 / 40%)
        height: 100vh
        padding: 1.67rem
        overflow: auto

    .UserFilesList
        @include flex(column, flex-start, flex-start)

        gap: 1.33rem
        padding: 1.67rem
        width: 100%
        margin-bottom: 4rem

        &:last-child .FileListItem:last-child
            margin-bottom: 20rem

    .FileListItem
        @include flex(row, center, space-between)

        cursor: pointer
        border-radius: .33rem
        padding: 1rem
        box-shadow: 0 0 .33rem 0 rgba(0,0,0,0.07)
        border: 1px solid transparent
        transition: all 300ms ease-in-out

        &:hover
            border: 1px solid #d1d4d7
            box-shadow: 0 .17rem .33rem 0 rgba(0,0,0,0.2)

        .left,.right
            @include flex(row, center, flex-start)

        .left
            font-weight: 500
            gap: 1rem

        .right
            justify-content: flex-end
            gap: 1.67rem

            opacity: 0.6

// To be refactored

.page-header
    @include flex(column, flex-start, flex-start)

    max-width: 40rem
    gap: .67rem
    padding: 1.67rem

    .heading
        font-weight: 800

    .description
        opacity: 0.4
