@import "../../../../lib/sass/styles/common"
@import "../../../../lib/sass/styles/colors/content-studio.sass"

.UsecaseListItem
    @include subheading
    @include default-transition
    @include flex(row, center, space-between)

    padding: 0.67rem 1rem
    cursor: pointer
    outline: none
    border-left: .17rem solid transparent
    border-right: .17rem solid transparent

    .lhs
        @include flex(row, center, flex-start)

        gap: 0.67rem

    .rhs
        @include flex(row, center, flex-start)

        color: var(--color-success-standard)

    .text
        @include flex(row, center, flex-start)

        font-weight: 400

    .usecase-icon
        height: 1.67rem
        width: 1.67rem

    &:hover
        background-color: var(--color-white-light)

    &[data-active="true"]
        color: var(--color-primary-standard)
        font-weight: 700
        border-left-color: var(--color-primary-standard)
        background-color: var(--color-primary-faint)

    &[data-disable="true"]
        color: var(--color-black-subdued)
        cursor: not-allowed

        .usecase-icon
            opacity: 0.4

    &[data-focus="true"],
    &:focus,
    &:focus-visible
        background-color: var(--color-primary-faint)
