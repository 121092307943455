@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)

@keyframes fadeOut
    0%
        opacity: 1
    100%
        opacity: 0

.Badge[data-type="success"]
    // duration | transition | delay | repeat | fill-mode | animation-name
    animation: 500ms linear 1s 1 both fadeOut

// Layout
.ProjectHeader
    display: grid
    grid-template-columns: 4rem 1fr 4rem
    grid-template-rows: 3.67rem
    grid-template-areas: "ProjectHeader-left ProjectHeader-middle ProjectHeader-right"

.ProjectHeader
    padding: 0
    box-shadow: 0 .33rem .33rem -0.17rem rgba(23, 43, 77, 0.08)
    border-bottom: 1px solid var(--color-white-standard)
    position: relative
    z-index: 2

    .ProjectHeader__left-actions,
    .ProjectHeader__right-actions
        display: grid
        align-items: center
        justify-items: center

    .ProjectHeader__left-actions
        grid-area: ProjectHeader-left
        border-right: $border-style

    .ProjectHeader__right-actions
        grid-area: ProjectHeader-right
        border-left: $border-style

    .ProjectHeader__content
        display: flex
        grid-area: ProjectHeader-middle
        align-items: center
        justify-content: space-between
        padding: 0 1.67rem 0 1rem

        .lhs
            @include flex(row, center, center)
            gap: 1rem

        .rhs
            @include flex(row, center, flex-end)
            gap: 0.67rem

    .ProjectName
        @include font-h4

        font-weight: 700
        padding: 0.33rem 0.67rem
        border-radius: 0.33rem

        &:focus,
        &:active,
        &:focus-visible
            outline: none
            border: none

        &:focus-visible
            background-color: var(--color-white-faint)
            box-shadow: 0 0.17rem 2rem -0.33rem rgb(23 43 77 / 64%)

    .Menu
        .menu-container
            min-width: 12rem
    .MenuItem:last-child
        color: var(--color-danger-standard)

    .ButtonSave
        .Button
            width: 6rem

            img
                filter: invert(100%) saturate(100%) brightness(200%) contrast(100%)
