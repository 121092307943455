@import "content-studio/src/lib/sass/index.sass"

.ContentEditor
    @include flex(row, flex-start, flex-start)
    gap: 0
    flex: 1

    min-width: 48rem

    .ToolbarLevelActions
        @include flex(row, center, center)
        border-bottom: 1px solid var(--color-white-standard)
        width: 3.67rem
        height: 3.67rem
        position: relative

        .icon-expand
            color: var(--color-black-standard)

            &.icon-expand--usecase
                left: 0.67rem
            &.icon-expand--metrics
                right: 0.67rem

    .ContentEditorCenterCol
        @include flex(column, flex-start, flex-start)
        gap: 0
        flex: 1

    .ContentEditorLeftCol
        @include flex(column, flex-start, flex-start)
        gap: 0

    .ContentEditorRightCol
        @include flex(column, flex-start, flex-start)
        gap: 0
