@import "./generic-mixins.sass"

.TextFieldLabel
    align-self: stretch
    color: var(--color-black-light)
    flex-direction: row
    font-family: Inter
    font-size: var(--font-size-button)
    font-style: normal
    font-weight: var(--font-weight-medium)
    justify-content: space-between
    line-height: var(--font-line-height-button)
