@import "content-studio/src/lib/sass/index.sass"

$border-style: 1px solid var(--color-black-faint)

.HistorySidebar
    @include flex(column, flex-start, flex-start)

    padding: 0
    gap: 0
    flex: 1

    .alertDot
        font-size: 1.67rem
        color: var(--color-danger-standard)
        position: absolute
        top: 0.33rem

    .TabsGroup
        @include flex(row, center, flex-start)

        font-weight: 500
        color: var(--color-black-light)
        padding: 0 1.67rem
        gap: 2rem
        border-bottom: $border-style
        height: 3.67rem
        z-index: 5
        position: relative
